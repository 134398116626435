import { cloud, three, audio, input } from "xx-packages";

const { cloudClient, typeManager } = cloud;
const { AppSingle } = three;

const { extAssetCache } = three;

const { musicMeta, audioManager } = audio || {};
const { inputManager } = input;

const loggableItems = {
  cloudClient,
  typeManager,
  extAssetCache,
  AppSingle,
  inputManager,
  musicMeta,
  audioManager,
};

function getErrorDetails(stack) {
  if (!stack) {
    return {
      errorFunctionCall: 'Undefined, no "stack" param passed t getErrorDEtais',
      errorLineNumber: -1,
    };
  }
  var caller_line = stack.split("\n")[1];
  let errorLineNumber = "",
    errorFunctionCall = "";

  if (caller_line) {
    var index = caller_line.indexOf("at ");
    var clean = caller_line.slice(index + 2, caller_line.length);

    errorLineNumber = clean.split(":");
    errorLineNumber =
      parseInt(errorLineNumber[errorLineNumber.length - 2].replace(/\)/, "")) +
      1;
    errorFunctionCall = clean
      // remove file source, which is in barcaker
      .replace(/\((.*)\)/, "")
      .replace(/\s/g, "");
  }
  return {
    errorFunctionCall,
    errorLineNumber,
  };
}
export default class PreviewActions {
  constructor() {
    this.hasError = false;
  }
  registerApp({ app, proxyUpdater }) {
    this.app = app;
    this.proxyUpdater = proxyUpdater;
  }
  reload = (_) => {
    const { app } = this;
    app.stop();
    window.location.reload();
  };
  stop = (_) => {
    const { app } = this;
    app.stop();
  };
  resume = (_) => {
    const { app } = this;
    app.resume();
  };
  screenshot = (_) => {
    const { app } = this;
    app.scene.takeThumbnailScreenshot({
      ctrlKey: true,
      altKey: true,
      key: "t",
    });
  };
  change = async (event) => {
    const { app } = this;

    const d = event.data.payload.data;
    if (d && d[">link"]) {
      const res = await cloudClient.find(
        d[">link"],
        { deps: true },
        { cacheResults: true, cacheTypeDefs: true }
      );
      const { t } = res.docs[0];
      // make sure we find and cache the type if necessary
      if (!typeManager.getClass(t)) {
        const tres = await typeManager.findTypeDef(t);
      }
    }

    if (app?.scene?.comp?.inputs) {
      app.scene.comp.inputs.set(
        event.data.payload.path,
        event.data.payload.data
      );
    }
  };
  updateGraph = (event) => {
    const { app, proxyUpdater } = this;
    if (!proxyUpdater) return;
    const d = event.data.payload;
    proxyUpdater.update(d);
  };
  eval = (event) => {
    try {
      console.log(eval(event.data.payload));
    } catch (err) {
      console.warn("Error evaluating command >>> ", err);
    }
  };
  log = (event) => {
    const type = event.data.payload;
    console.log(loggableItems[type]);
  };
  stats = (_) => {
    const stats = {
      ccCachedDocs: Object.keys(cloudClient._cache).length,
      cachedGeoms: Object.keys(extAssetCache.geometries).length,
      cachedModels: Object.keys(extAssetCache.models).length,
      cachedTextures: Object.keys(extAssetCache.textures).length,
      tmDefs: Object.keys(typeManager.defs).length,
      tmTypesById: Object.keys(typeManager.typesById).length,
    };
    window.parent.postMessage(
      {
        type: "stats",
        payload: stats,
      },
      "*"
    );
  };

  onError = ({ message, stack }) => {
    const { app, hasError } = this;
    if (app) app.stop();
    if (hasError) return;
    console.log("Preview on error msg", message, "stack", stack);
    this.hasError = true;

    const { errorFunctionCall, errorLineNumber } = getErrorDetails(stack);

    window.parent.postMessage(
      {
        type: "JsError",
        payload: {
          message,
          stack,
          errorFunctionCall,
          errorLineNumber,
        },
      },
      "*"
    );

    const errorDiv = document.createElement("div");
    document.body.appendChild(errorDiv);
    errorDiv.innerHTML = `
        <div style="padding:3vw">
            <p>Error at ${errorFunctionCall} line ${errorLineNumber}</p>
            <p style="max-width:90vw">message: ${message}
            </p>
            <p style="max-width:90vw">stack: ${stack}
            </p>
        </div>
        `;
    errorDiv.style = `
            position: fixed;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 9999999999;
            background: black;
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-right: auto;
            margin-left: auto;
            font-size: 2em;
            font-size: 20px;
        `;
    document.getElementById("canvas").style.height = 0;
    if (!app) return;
    app.update = (_) => null;
  };
}
