import { cloud, three, data, rt, audio } from "xx-packages";

// MODEL LOADERS
import { OBJLoader } from "./loaders/OBJLoader.js";
import { GLTFLoader } from "./loaders/GLTFLoader.js";
import { DRACOLoader } from "./loaders/DRACOLoader.js";
import { FBXLoader } from "./loaders/FBXLoader";
import { RGBELoader } from "./loaders/RGBELoader.js";
// import Preloader from "./Preloader"
import { KTX2Loader } from "./loaders/KTX2Loader";
import { WebGLRenderer, REVISION } from "three";
import device from "./Device";

import PreviewActions from "./previewActions.js";

import "./index.css";
import renderPlayer from "./MusicPlayer.js";

/* globals preloader */

const { cloudClient, typeManager } = cloud;
const { AppSingle, modelLoader } = three;
const { queryStringToObject } = data.conversion;

const { extAssetCache } = three;
const { ProxyUpdaters } = rt;

const preloader = window.preloader;

// REGISTER LOADERS

modelLoader.registerLoader({
  loader: OBJLoader,
  type: "OBJ",
});
modelLoader.registerLoader({
  loader: GLTFLoader,
  type: "GLTF",
});
modelLoader.registerLoader({
  loader: FBXLoader,
  type: "FBX",
});
extAssetCache.RGBELoader = new RGBELoader();

const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath(
  process.env.NODE_ENV === "development"
    ? process.env.PUBLIC_URL + "/draco/"
    : process.env.REACT_APP_DRACO_URL
);

modelLoader.registerLoader({
  loader: dracoLoader,
  type: "DRACO",
});

const ktx2Loader = new KTX2Loader();
ktx2Loader.setTranscoderPath(
  process.env.NODE_ENV === "development"
    ? process.env.PUBLIC_URL + "/basis/"
    : process.env.REACT_APP_DRACO_URL
);
const renderer = new WebGLRenderer();
ktx2Loader.detectSupport(renderer);
modelLoader.registerKTX2Loader(ktx2Loader);

const params = queryStringToObject(window.location.search);

/* DEFAULT CONSTANTS - OBSOLATE ******************************************/

const inputManConf = {
  mkb: {
    enabled: true,
    channels: {
      ui: {
        enabled: false,
        count: 2,
      },
    },
  },
  touch: {
    enabled: false,
  },
  gamepad: {
    enabled: false,
  },
  midi: {
    enabled: !!params.forwardMIDI,
  },
  network: {
    enabled: false,
    p2pBrokerUrl: "wss://vjycloud.herokuapp.com/p2p/",
    peerId: params.peerId,
    connection: {
      peerId: params.remotePeerId,
      retryIntervalSeconds: 10,
      maxRetries: 5,
      forwardMIDI: params.forwardMIDI,
    },
  },
};

const appDecl = {
  settings: {
    cloud: {
      //includeMeta: false
      loadThumbnails: false,
    },

    inputMan: inputManConf,

    audioMan: {
      musicMeta: {},
    },
  },

  ui: {
    main: null,
    sys: {
      enabled: true,
      visible: false,
    },
  },

  project: {
    doAddHTML: true,
    doAddCSS: true,
    doScroll: false,
    allowSceneHTML: true,
    override: {},
    n: "Acuario",
  },
  dominantContext: "composition",
};

const uiExp = {
  t: "UIExp",
  d: {
    infoBox: {
      enabled: true,
    },
    paramStack: {
      enabled: true,
      elems: [{ t: "effect" }, { t: "color" }],
    },
  },
};

// window._params = {
// 	compOverrideSettings: {
// 		rot0: 0,
// 		rotSection: 0,
// 		y0: -0.18
// 	}
// }
console.log("BUILDINFO");
if (window?._params?.compId) {
  params.compId = window._params.compId;
}
if (window?._params?.project && window?._params?.project?.length) {
  params.project = window._params.project;
}
if (window?._params?.compOverrideSettings) {
  params.compOverrideSettings = window._params.compOverrideSettings;
  appDecl.compOverrideSettings = window._params.compOverrideSettings;
}
if (window?._params?.doAddHTML !== undefined) {
  appDecl.project.doAddHTML = window?._params?.doAddHTML;
}
if (window?._params?.doAddCSS !== undefined) {
  appDecl.project.doAddCSS = window?._params?.doAddCSS;
}
if (window?._params?.doScroll !== undefined) {
  appDecl.project.doScroll = window?._params?.doScroll;
}

if (!document.getElementById("canvas")) {
  const canvas = document.createElement("canvas");
  canvas.id = "canvas";
  document.body.insertBefore(canvas, document.querySelector("#content"));
}

async function startApp() {
  let config = window._config;

  if (process.env.NODE_ENV === "production" && window?._params?.cloudBaseUrl) {
    // build step
    let cloudBaseUrl = "./";

    if (window?._params?.cloudBaseUrl)
      cloudBaseUrl = window?._params?.cloudBaseUrl;

    let cloudInitSettings = {
      apiBaseUrl: "", //config.cloud.apiBaseUrl,
      storage: window.localStorage,
      settings: appDecl.settings.cloud,
      cloudBaseUrl,
    };

    // if we have a window config, honour it
    // eg for cases where we synced different projects in different folders,
    // but we use the same web app for all of them
    if (config) {
      cloudInitSettings = {
        apiBaseUrl: config.cloud.apiBaseUrl,
        storage: window.localStorage,
        settings: appDecl.settings.cloud,
        cloudName: config.cloud.cloudName,
        cloudBaseUrl,
      };
    }

    await cloudClient.init(cloudInitSettings);

    if (window?._params?.doAddHTML === undefined)
      appDecl.project.doAddHTML = false;
    if (window?._params?.doAddCSS === undefined)
      appDecl.project.doAddCSS = false;
    if (window?._params?.doScroll === undefined)
      appDecl.project.doScroll = true;
  } else {
    if (!config) {
      try {
        const res = await fetch(process.env.PUBLIC_URL + "/config.json");
        config = await res.json();
      } catch (err) {
        console.warn(
          "Error fetching config at: " +
            process.env.PUBLIC_URL +
            "/config.json",
          err
        );
      }
    }

    if (!config) throw new Error("No config found");

    await cloudClient.init({
      apiBaseUrl: config.cloud.apiBaseUrl,
      storage: window.localStorage,
      settings: appDecl.settings.cloud,
      cloudName: config.cloud.cloudName,
      // cloudBaseUrl: '/',
    });
  }

  const user = await cloudClient.getCurrentUser();

  console.log("USER", user);

  if (!device.hasWebGL) {
    return preloader.showUnsupportedMessage();
  }

  if (params.assetId) {
    appDecl.asset = { ">link": { id: params.assetId } };
    appDecl.project.override = {
      scene: {
        startComp: { ">link": { id: params.assetId } },
      },
    };
  } else if (params.compId) {
    appDecl.asset = { ">link": { id: params.compId } };
    appDecl.project.override = {
      scene: {
        startComp: { ">link": { id: params.compId } },
      },
    };
  }

  if (params.project) {
    if (params.project == "View.AV") {
      appDecl.project.id = user.settings.viewAV[">link"].id;
    } else {
      appDecl.project.n = params.project;
    }
  } else appDecl.project.id = user.settings.view[">link"].id;

  const app = new AppSingle(appDecl);
  app.preloader = preloader;
  app.device = device;
  app.enableScreenshot = true;
  app.scene.enableScreenshot = true;

  // when in an iframe, app needs to know this
  // that way it won't create graph
  if (window.parent) app.isIframePreview = true;

  let previewActions;
  if (window.parent && window.location !== window.parent.location) {
    // when running in iframe, send touch events to parent window so we can prevent scrolling when
    // user is interacting with this app
    window.addEventListener("touchstart", (_) => {
      window.parent.postMessage(
        {
          type: "touchstart",
        },
        "*"
      );
    });
    window.addEventListener("touchend", (_) => {
      window.parent.postMessage(
        {
          type: "touchend",
        },
        "*"
      );
    });
    // create the actions that can be exectuted in response to messages from the parent window
    // console.log('View app : listen for unhandled errors')
    previewActions = new PreviewActions();
    window.addEventListener("error", (e) => {
      console.log("view app window error", e);
      app.stop();
    });
    window.addEventListener("unhandledrejection", (e) => {
      console.log("window unhandled rejection", e);
      const { message, stack } = e.reason;
      previewActions.onError({ message, stack });
    });
  }

  await app.start();
  // app.start();

  console.log("APP DECEL", appDecl);

  if (appDecl.asset) {
    const doc = cloudClient.getDoc(appDecl.asset);
    if (doc?.m?.tags && doc.m.tags.includes("AV")) {
      renderPlayer();
    }
    console.log("asset doc", doc);
  }

  window.app = app;

  if (!window.parent || window.location == window.parent.location) return;

  let proxyUpdater;
  let hasError = false;

  // wait for the composition to be ready
  const wait = setInterval((_) => {
    if (!app?.scene?.comp?.inputs) return;

    // create a proxy updater that will use iframe messages to update the composition's inputs
    proxyUpdater = new ProxyUpdaters.VisCompUpdaterFromData(app.scene.comp);
    clearInterval(wait);
    previewActions.registerApp({ app, proxyUpdater });

    window.parent.postMessage("isReady", "*");

    // listen for commands from the parent window
    window.addEventListener(
      "message",
      async (event) => {
        if (!event.data.type || !previewActions[event.data.type]) return;
        previewActions[event.data.type](event);
      },
      false
    );
  }, 100);

  // monitor.initUI()
}

startApp();
