import React, { useState, useEffect, useRef, memo } from "react";
import cloneDeep from "lodash/cloneDeep";
import "./MusicPlayer.css";
import { last, set } from "lodash";

// pure function component, as we don;t
const TempoFlasher = memo(({ musicMeta, setBPM }) => {
  // update div using its DOM reference to avoid React re-renders
  const output = useRef(null);
  const lastClick = useRef(null);
  const onUpdate = (_) => {
    if (!output.current) return;
    output.current.style.opacity = musicMeta.beatFloat % 1;
  };

  const calcTempo = () => {
    const now = performance.now();
    if (lastClick?.current) {
      const delta = (now - lastClick.current) / 1000;
      const bpm = Math.round(60 / delta);
      musicMeta.setBPM(bpm);
      setBPM(bpm);
      console.log("detla", delta);
    }
    lastClick.current = now;
  };

  useEffect(() => {
    // Anything in here is fired on component mount.
    musicMeta.inputs.listeners.add("update", onUpdate);
    return () => {
      // Anything in here is fired on component unmount.
      musicMeta.inputs.listeners.remove("update", onUpdate);
    };
  }, []);

  return <div class="TempoFlasher" ref={output} onClick={calcTempo}></div>;
});

export default TempoFlasher;
