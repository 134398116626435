import React, { useState, useEffect, useRef, memo } from "react";
import cloneDeep from "lodash/cloneDeep";
import "./MusicPlayer.css";
import { last, set } from "lodash";
import { input, cloud } from "xx-packages";
import TempoFlasher from "./TempoFlasher";
const { inputManager } = input;
const { typeManager } = cloud;
console.log("INPUT", input);
const MusicPlayer = memo((props) => {
  // PROPS
  const { musicMeta } = props;

  // REFS
  const timeRef = React.createRef();
  const beatRef = React.createRef();
  const bpmRef = React.createRef();

  // STATE
  // The useState hook takes an initial state as parameter and returns an array
  // which holds the current state as first item and a function to change the state as second item.
  const [audioManParams, setAudioManParams] = useState({});
  const [musicMetaParams, setMusicMetaParams] = useState({});
  const [playerVisible, togglePlayer] = useState(false);
  const [bpm, setBPM] = useState(audioManParams?.clip?.bpm);
  const [showMidi, toggleMidi] = useState(false);
  const [playing, togglePlay] = useState(false);
  // Play | Input
  const [inputMode, setInputMode] = useState(false);
  // Link | Upload | Playlist
  const [playMode, setPlayMode] = useState(false);
  const [audioLoaded, setAudioLoaded] = useState(false);
  const [audioLoadProgress, setAudioLoadProgress] = useState(0);
  const [startingAudio, setStartingAudio] = useState(false);
  const [clipName, setClipName] = useState(null);
  const [started, setStarted] = useState(false);
  const [inputModePreStart, setInputModePreStart] = useState("Demo");

  // COMPONENT LIFECYCLE
  useEffect(() => {
    // Anything in here is fired on component mount.
    musicMeta.inputs.listeners.add("setParams", setParams);
    musicMeta.inputs.listeners.add("audioLoadProgress", onAudioLoadProgress);

    let clipName = musicMeta.getAudioParams().clip?.url;
    if (musicMeta.getAudioParams().mode === "Input") clipName = "Microphone";
    setClipName(clipName);
    setPlayMode(
      musicMeta.getAudioParams().mode === "Input" ? "Input" : "Playlist"
    );
    setInputMode(musicMeta.getAudioParams().mode);
    togglePlay(musicMeta.playing);
    onChangeInputMode({
      target: {
        value: "Demo",
      },
    });
    window.am.startAudio();

    musicMeta.play();
    return () => {
      // Anything in here is fired on component unmount.
      musicMeta.inputs.listeners.remove("setParams", setParams);
      musicMeta.inputs.listeners.remove(
        "audioLoadProgress",
        onAudioLoadProgress
      );
    };
  }, []);

  //  Map MM / AudioMan params to component state
  const setParams = (val) => {
    setBPM(val.data.audioMan?.clip?.bpm);

    setInputMode(val.data.audioMan.mode);

    if (!playMode && val.data.audioMan.mode === "Play") setPlayMode("Playlist");

    setAudioManParams(cloneDeep(val.data.audioMan));
    setMusicMetaParams(cloneDeep(val.data.musicMeta));

    let clipName = val.data.audioMan?.clip?.url;
    if (val.data.audioMan?.mode === "Input") clipName = "Microphone";
    if (playMode !== "Upload") setClipName(clipName);

    console.warn("SET PARAMS", clipName);
  };

  const onAudioLoadProgress = (e) => {
    console.log(e);
    setAudioLoadProgress(e.data);
  };

  // Real time beat / time monitoring
  musicMeta.inputs.listeners.add("update", (msg) => {
    // console.log( msg.data )
    if (timeRef.current)
      timeRef.current.textContent =
        msg.data.currentTime && msg.data.currentTime.toFixed(1);
    if (beatRef.current && msg.data.beatFloat)
      beatRef.current.textContent = ` ${Math.floor(
        msg.data.beatFloat / 4 + 1
      )} | ${Math.floor((msg.data.beatFloat % 4) + 1)} `;
  });
  musicMeta.inputs.listeners.add("play", (msg) => {
    togglePlay(true);
  });
  musicMeta.inputs.listeners.add("pause", (msg) => {
    togglePlay(false);
  });
  musicMeta.inputs.listeners.add("audioLoaded", (msg) => {
    console.log("SET audio loaded");
    setAudioLoaded(true);
  });
  // INPUT CHANGE FUNCTIONS
  const onChangeInputMode = async (e) => {
    console.log("change input mode ", e.target.value);
    if (e.target.value === "Mic") {
      setPlayMode("Input");
      setClipName("Microphone");
      await musicMeta.setInput("Input");
    }
    if (e.target.value === "Link") {
      setPlayMode("Link");
    }
    if (e.target.value === "Playlist") {
      setPlayMode("Playlist");
    }
    if (e.target.value === "Upload") {
      setPlayMode("Upload");
    }
    if (e.target.value === "Demo") {
      setPlayMode("Demo");
      musicMeta.setInput("Demo");
    }
  };
  const onChangeInputModePreStart = (e) => {
    setInputModePreStart(e.target.value);
  };

  const onChangeInputSrc = (e) => {
    // Enter key pressed
    // should use event.code instead but it looks like it's not present in React events, which is bizarre
    if (e.keyCode !== 13) return;

    const url = e.target.value;

    //regexp for matching URLS : https://regexr.com/39nr7
    const rx =
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    const valid = rx.test(url);
    console.log("set url", url, valid);
    if (!valid) {
      return alert("Please provide a valid url");
    }
    setAudioLoaded(false);
    musicMeta.pause();
    musicMeta.setInput("Play", { url });
  };
  const onChangePlaylistTrack = (e) => {
    const url = e.target.value;
    setPlayMode("Playlist");
    setAudioLoaded(false);
    musicMeta.pause();

    musicMeta.setInput("Play", { url });
    musicMeta.setBPM(120);
  };

  // FIle upload
  const handleDrop = (e) => {
    let files = [...e.target.files];
    const file = files[0];

    const fileUrl = URL.createObjectURL(file);
    setPlayMode("Upload");
    setAudioLoaded(false);
    musicMeta.pause();

    musicMeta.setInput("Play", { url: fileUrl });
    musicMeta.setBPM(120);
    setPlayMode("Upload");
    setClipName(file.name);
  };

  const onBpmChange = (e) => {
    console.log(e.keyCode);

    if (
      isNaN(String.fromCharCode(e.which)) &&
      // .
      e.keyCode !== 190 &&
      // backspace
      e.keyCode !== 8 &&
      // enter
      e.keyCode !== 13 &&
      // left
      e.keyCode !== 37 &&
      // right
      e.keyCode !== 39
    ) {
      return e.preventDefault();
    }
    if (e.keyCode !== 13) return;
    e.preventDefault();
    const value = parseFloat(bpmRef.current.textContent);
    musicMeta.setBPM(value);
  };

  // Transport
  const playOrPause = (_) => {
    console.log("pppp");

    const doPlay = !playing;

    if (doPlay) musicMeta.play();
    else musicMeta.pause();
  };
  const stop = (_) => {};

  let isLoading = false;
  if (inputMode !== "Input" && !audioLoaded) isLoading = true;

  const startAudio = async (_) => {
    console.log("start audio", playMode);
    if (startingAudio) return;
    setStartingAudio(true);
    await onChangeInputMode({
      target: {
        value: inputModePreStart,
      },
    });

    // audioManager.setParams(props.app.params.settings.audioMan);
    // audioManager.start();
    if (inputModePreStart === "Playlist") {
      onChangePlaylistTrack({
        target: {
          value: "https://socialvisuals.com/webgl/music/AA2.mp3",
        },
      });
      // musicMeta.play();
    }
    console.log("START", playMode);
    inputManager.startMidi();
    await window.am.startAudio();
    console.log("MM start play");
    musicMeta.play();
    setStarted(true);
    setStartingAudio(false);
    props.setAudioStarted();
  };
  const { assetDoc } = props;
  if (!started) {
    return (
      <div className="MusicStartPrompt">
        {!startingAudio && (
          <div>
            <div className="Launch">
              <div className="Title">
                {props.assetDoc.m.n} - {props.compDoc.m.n}
              </div>
              <legend>
                This is a music reactive composition,<br></br>select audio
                source:
              </legend>
              <fieldset>
                <label>
                  <span>Microphone</span>

                  <input
                    type="radio"
                    name="playMode"
                    checked={inputModePreStart === "Mic"}
                    onChange={(_) =>
                      onChangeInputModePreStart({ target: { value: "Mic" } })
                    }
                  />
                </label>
                <label>
                  <span>Music Playback</span>

                  <input
                    type="radio"
                    name="playMode"
                    checked={inputModePreStart === "Playlist"}
                    onChange={(_) =>
                      onChangeInputModePreStart({
                        target: { value: "Playlist" },
                      })
                    }
                  />
                </label>
                <label>
                  <span>No Audio (Demo Waves)</span>
                  <input
                    type="radio"
                    name="playMode"
                    checked={inputModePreStart === "Demo"}
                    onChange={(_) =>
                      onChangeInputModePreStart({ target: { value: "Demo" } })
                    }
                  />
                </label>
              </fieldset>
            </div>
            <button onClick={(_) => startAudio()}>START</button>
          </div>
        )}
        {startingAudio && (
          <div>
            <div>Starting audio, please wait...</div>
            <div className="Progress">
              <div>
                {(audioLoadProgress * 100).toFixed(0).padStart(2, 0) + "%"}
              </div>
              <div className="ProgressBar">
                <div
                  className="Value"
                  style={{ width: audioLoadProgress * 100 + "%" }}
                ></div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="MusicPlayer">
      <button onClick={(_) => togglePlayer(!playerVisible)} className="Close">
        {playerVisible ? (
          "X"
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 104.23 122.88"
            style={{ enableBackground: "new 0 0 104.23 122.88" }}
            width="100%"
            height="100%"
          >
            <g>
              <path
                fill="white"
                className="st0"
                d="M87.9,78.04c2.74-0.48,5.33-0.4,7.6,0.13V24.82L39.05,41.03v61.95c0.03,0.34,0.05,0.69,0.05,1.03 c0,0,0,0.01,0,0.01c0,8.34-8.75,16.62-19.55,18.49C8.76,124.37,0,119.12,0,110.77c0-8.34,8.76-16.62,19.55-18.48 c4.06-0.7,7.84-0.39,10.97,0.71l0-76.26h0.47L104.04,0v85.92c0.13,0.63,0.2,1.27,0.2,1.91c0,0,0,0,0,0.01 c0,6.97-7.32,13.89-16.33,15.44c-9.02,1.56-16.33-2.83-16.33-9.8C71.57,86.51,78.88,79.59,87.9,78.04L87.9,78.04L87.9,78.04z"
              />
            </g>
          </svg>
        )}
      </button>
      <div class={"content " + (playerVisible ? "" : "hidden")}>
        {/* TRACK INFO */}

        <div className="TrackInfo">
          <div>
            <div className="Title">{clipName}</div>
            <div className="Time">
              {inputMode !== "Input" && (
                <div>
                  {/* BEAT COUNT */}
                  Beat: <span ref={beatRef}></span>
                </div>
              )}
              {inputMode !== "Input" && (
                <div>
                  Time: <span ref={timeRef}></span>
                </div>
              )}
            </div>
          </div>

          {isLoading && <div>Loading</div>}
        </div>
        <div class="Controls">
          {/* TRANSPORT */}
          {!isLoading && (
            <div className="TransportControls">
              <button
                onClick={playOrPause}
                className={playing ? "Pause" : "Play "}
              >
                <span class="visually-hidden">Play</span>
              </button>
              {clipName !== "Microphone" && (
                <button onClick={stop} className="Stop">
                  <span class="visually-hidden">Stop</span>
                </button>
              )}
            </div>
          )}

          {/* BPM INFO */}

          <div className="BPM">
            <div>
              BPM:
              <span contentEditable={true} onKeyDown={onBpmChange} ref={bpmRef}>
                {bpm}
              </span>
            </div>
            <TempoFlasher musicMeta={musicMeta} setBPM={setBPM}></TempoFlasher>
          </div>
        </div>

        {/* INPUT MODE SELECT */}
        <div class="InputSelect">
          <label htmlFor="inputModeSelect">Choose an input mode:</label>
          <select
            id="inputModeSelect"
            onChange={onChangeInputMode}
            // value={playMode}
          >
            <option value="Playlist" selected={playMode === "Playlist"}>
              Playlist
            </option>
            <option value="Link" selected={playMode === "Link"}>
              Link
            </option>
            <option value="Mic" selected={playMode === "Input"}>
              Mic
            </option>
            <option value="Upload" selected={playMode === "Upload"}>
              Upload
            </option>
          </select>
        </div>

        {playMode === "Link" && (
          <div class="LinkSelect">
            <label for="fileUpload">
              Choose a link to an audio file, then press Enter:
            </label>
            <input
              type="text"
              placeholder="https://socialvisuals.com/webgl/music/ArthurTestAudio.mp3"
              onKeyUp={onChangeInputSrc}
            ></input>
          </div>
        )}

        {/* PLAYLIST */}
        {playMode === "Playlist" && (
          <div class="Playlist">
            <label htmlFor="playlistSelect">Choose a song:</label>
            <select id="playListSelect" onChange={onChangePlaylistTrack}>
              <option value="https://socialvisuals.com/webgl/music/ArthurTestAudio.mp3">
                Arthur Test
              </option>
              <option value="https://socialvisuals.com/webgl/music/06_Herztod.mp3">
                Test2
              </option>
              <option value="https://socialvisuals.com/webgl/music/Abakus_Storm.mp3">
                Test3
              </option>
              <option value="https://socialvisuals.com/webgl/music/AA2.mp3">
                Test3
              </option>
            </select>
          </div>
        )}

        {/* FILE UPLOAD */}
        {playMode === "Upload" && (
          <div class="AudioUploader">
            <label for="fileUpload">Choose or drop an audio file:</label>
            <input
              type="file"
              id="fileUpload"
              name="fileUpload"
              accept="audio/*"
              onChange={handleDrop}
            ></input>
          </div>
        )}

        {/* MIDI INFO */}
        {/* <div class={'MidiInfo ' + (showMidi? 'modal' : '') }>
                <button id='MidiModalOpen' onClick={_=> toggleMidi( !showMidi )}>
                    Midi Info
                </button>
                <div className='Modal'>
                    <button onClick={_=> toggleMidi( false )}>
                        close
                    </button>
                    MIDDDIDID
                </div>
                
            </div> */}
      </div>
    </div>
  );
});
export default MusicPlayer;
