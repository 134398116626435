(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("THREE"), require("XX"));
	else if(typeof define === 'function' && define.amd)
		define(["THREE", "XX"], factory);
	else if(typeof exports === 'object')
		exports["view"] = factory(require("THREE"), require("XX"));
	else
		root["view"] = factory(root["THREE"], root["XX"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__3__) {
return 