import ReactDOM from "react-dom";
import React from "react";
import MusicPlayer from "@react/monitor/music/MusicPlayer";
import { audio } from "xx-packages";
const { musicMeta } = audio || {};

console.log("REACTDOM", ReactDOM, React);
export default function renderPlayer() {
  ReactDOM.render(
    <MusicPlayer musicMeta={musicMeta} />,
    document.getElementById("root")
  );
}
